/* eslint-disable no-unused-vars */
import { Space, Table } from 'antd';
import React, { useState,useEffect} from 'react';
import DatePicker from "react-datepicker";
//import AppelsActions from "./actions"
import StatusActions from './statusActions';
import appel from "../../services/appel";
import moment from "moment"
const { RangePicker } = DatePicker;

const AppelMaster = () => {
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [content, setContent] = useState("");

  useEffect(() => {
    setLoggedIn(false)
    appel.getAppels().then(
      (response) => {
        setContent(response.data.response.appeldefonds);
        setLoggedIn(true)
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          
      }
    );
  }, []);
   
 
  const handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
     setFilteredInfo(filters);
     setSortedInfo(sorter);
   };
   const [dates, setDates] = useState(null);
   const [value, setValue] = useState(null);
 
   function convert(str) {
     var date = new Date(str),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
   }
 
   function convertreturnValue1() {
     if(value !== null){
     var date = new Date(value[0]._d),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
     }
   }
 
   function convertreturnValue2() {
     if(value !== null){
     var date = new Date(value[1]._d),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
     }
   }
   const disabledDate = (current) => {
     if (!dates) {
       return false;
     }
     const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
     return !!tooEarly || !!tooLate;
   };
   const onOpenChange = (open) => {
     if (open) {
       setDates([null, null]);
     } else {
       setDates(null);
     }
     if(value !== null){
       
     }
     
   };
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      filters: [
        {
          text: ' ',
          value: ' ',
        },
      ],
      filteredValue: filteredInfo.date || null,
      onFilter: (value, record) => record.date.includes(value),
      sorter: (a, b) => a.nom.length - b.nom.length,
      sortOrder: sortedInfo.columnKey === 'nom' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return record?.user
         },
    },
    {
        title: 'Montant',
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => a.amount.length - b.amount.length,
        sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text, record) => {
          return record?.amount
           },
    },
    
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY  h:mm:ss");

         },
        filters :[
        {
          text:<RangePicker
                  value={dates || value}
                  disabledDate={disabledDate}
                  onCalendarChange={(val) => setDates(val)}
                  onChange={(val) => setValue(val)}
                  onOpenChange={onOpenChange}
               />,
              value: convertreturnValue1()
        }
      ],
      filteredValue: filteredInfo.date || null,
      onFilter: (value, record) => record.date.includes(value),
      sorter: (a, b) => a.date.length - b.date.length,
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Etat',
      dataIndex: 'statut',
      key: 'statut',
      filters: [
        {
          text: 'Pending',
          value: 'Pending',
        }, 
          {
            text: 'Failed',
            value: 'Failed',
          },
        {
          text: 'Successs',
          value: 'Success',
        },
      ],
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.address.length - b.address.length,
      sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return <StatusActions record = {record}/>
         },
    },
    
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
      </Space>
      <Table columns={columns} pagination={{ pageSize: 5}} loading ={!isLoggedIn} dataSource={content} onChange={handleChange} />
    </>
  );
};
export default AppelMaster;
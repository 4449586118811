import axios from "axios";
import authHeader2 from "./auth-header2";
import authHeader from "./auth-header";


const getBalance = (userId) => {
  const data = JSON.stringify({
    "userId": userId,
  });
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/balance`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

 const getAllTransactions = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/transactions`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

const getAllReclamations = () => {
  
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "userId":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/reclamationsByReceiverId`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const getAllSup = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/superviseur/getAll`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const getAllSupClients = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/superviseurClients/getAll`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const createCaissier = (firstName,lastName,username,phone,CNI,email,adresse,created_by,img1,img2) => {
  const data = new FormData();
  data.append('firstName', firstName);
  data.append('lastName', lastName);
  data.append('username', username);
  data.append('phone', phone);
  data.append('CNI', CNI);
  data.append('email', email);
  data.append('adresse', adresse);
  data.append('created_by', created_by);
  data.append('img1', img1);
  data.append('img2', img2);

  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/caissier`,
    headers: authHeader2(),
    data : data
  };
  return axios(config)
};

const getAllCaissiersMaster = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/caissiers/getAll`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const getAllCaissiers = (id) => {
  
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/caissiers`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const generateOtp  = (userId)=>{  
  const data = JSON.stringify({
    "userId": userId,
  });
    const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/generateOtp`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
}

const verifyPhoneOtp = (userId,otp)=>{

  var data = JSON.stringify({
    "userId": userId,
    "otp": otp
  });
  
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/verify`,
    headers:authHeader(),
    data : data
  };
  
   return axios(config)

}

const nbTrx = (userId) => {
  // const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
  const data = JSON.stringify({
    "id": userId,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countAll`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbReclamations = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countReclamations`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbSupCom = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countSupComm`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbSupClients = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countSuperviseurs`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbCaissiers = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countCaissier`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const updateProfile = (userId,firstName,lastName,email,phone) => {
  const data = JSON.stringify({
    "userId": userId,
    "firstName": firstName,
    "lastName": lastName,
    "email": email,
    "phone": phone
  });
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/updateProfile`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

const updatePwd = (userId,password,newPassword) => {
  const data = JSON.stringify({
    "userId": userId,
    "password": password,
    "newPassword": newPassword
    
  });
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/updatepwd`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    nbCaissiers,
    getAllCaissiersMaster,
    createCaissier,
    updatePwd,
    updateProfile,
    getBalance,
    getAllTransactions,
    getAllReclamations,
    generateOtp,
    verifyPhoneOtp,
    getAllSup,
    getAllSupClients,
    nbTrx,
    nbReclamations,
    nbSupCom,
    nbSupClients,
    getAllCaissiers
  };
import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column, Row, Button, Img, Text, Input, List, Stack } from "components";
import Sidebar from "components/Sidebar/Sidebar";
import { Navigate, useNavigate } from "react-router-dom";
import { Modal } from 'antd';
import OTPInput from "otp-input-react";
import user from "../../services/user";
import {create} from "../../actions/reappro"
import ApproMaster from "components/ReapproMaster/Listereappro";
import { verifyPhoneOtp } from "../../actions/user";
import { EyeInvisibleTwoTone,EyeTwoTone } from '@ant-design/icons';
//import {logout} from '../../actions/auth'

const ReapprovisionnementPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector(state => state.auth);
  const [showBalanceIcon, setShowBalanceIcon] = useState(false);
  const { message } = useSelector(state => state.message);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const  [montant, setMontant]  = React.useState ("");
  const [isdisabled, setDisabled] = React.useState(false);
  const  [otp, setOtp]  = React.useState ("");
  const [balance, setBalance] = useState("");
  const [successful, setSuccessful] = useState(false);
  //const { status } = useSelector(state => state.status);
  let calssSoled = true;
 
  if (currentUser==null) {
  <Navigate to="/" />;
  }
  useEffect(() => {

    if (currentUser) {
    user.getBalance(currentUser.response.user.id).then(
      (response) => {
        setBalance(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setBalance(_content);
      }
    );
  }
  
  }, );

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }
  function isValidMontant(montant) {
    if(!montant) return true
  }
  const onChangeMontant = (e) => {
    if(isValidMontant(e.target.value)){
      setDisabled(false)
    }
    if(!isValidMontant(e.target.value)){
      setDisabled(true)
      //const m = e.target.value;
      setMontant(e.target.value);
    }else{
      setMontant(null)
    }
  }
  const generate = () => {
    setIsModalOpen(true);
  };
 
  const  handleSubmit =  (e) => {
    e.preventDefault();
  
    setSuccessful(false);
    
    dispatch(verifyPhoneOtp(currentUser.response.user.id, otp))
      .then(() => {
        setSuccessful(true);
        dispatch(create(montant,currentUser.response.user.username,currentUser.response.user.id,currentUser.response.user.created_by))
        setIsModalOpen(false);
        setIsModalOpen1(true);
        navigate("/reapprovisionnement");
          //window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
          //  if(status === 401){
            
          //   navigate("/");
          //   logout()
          // }
      });
  
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  function handleNavigate5() {
    navigate("/dashboardmaster");
  } 
  function handleNavigate6() {
    navigate("/appeldefonds");
  } 
  function handleNavigate7() {
     window.location.reload()
  }
  if(100000 >= parseInt(balance.solde) ){
    calssSoled = false
  }
  if (!isLoggedIn) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <>
        <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
          <Row className="justify-between w-[98%]">
            <Sidebar className="w-[20%] " />
            <Column className="bg-white_A700 items-center lg:py-[5px] xl:py-[6px] 2xl:py-[7px] 3xl:py-[9px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
              <Column className="items-center justify-start lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] w-[100%]">
                <Row className="items-start w-[92%]">
                  <Button
                    className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center mt-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                    size="smIcn"
                    variant="icbFillGray201"
                  >
                    <Img
                      src="images/img_arrowleft.svg"
                      className="flex items-center justify-center"
                      onClick={handleNavigate5}
                      alt="arrowleft"
                    />
                  </Button>
                  <Text className="Modifieruncai" as="h5" variant="h5">
                    Demande de Reapprovisionnement
                  </Text>
                

             {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
             <Text className="text-black_900 p-2 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             <button
             onClick={handleBalanceIconClick}> <EyeTwoTone twoToneColor="#674788" size="60px" title=" Afficher Solde"/> </button>
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
              <Text className="text-black_900 p-2 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              <button  onClick={handleBalanceIconClick}> <EyeInvisibleTwoTone  twoToneColor="#674788" size="50px" title=" Masquer Solde"/> </button>
            </Row>
             )}
                </Row>
                 
                <Column className="justify-start lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[93%]">
                   
                    <Text className="fcfaCounter_Two" as="h5" variant="h5">
                      Saisissez le montant du réapprovisionnement
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupFour"
                      wrapClassName="2xl:mt-[15px] 3xl:mt-[18px] lg:mt-[10px] w-[100%] xl:mt-[13px]"
                      name="Group381"
                      placeholder="montant de la demande"
                      value={montant}
                      onChange = {onChangeMontant}
                    ></Input> 
                    <Button
                    disabled={!isdisabled ? (
                        "true"
                      ) : ""}
                      className="font-semibold lg:mt-[40px] xl:mt-[127px] 2xl:mt-[142px] 3xl:mt-[171px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                      shape="RoundedBorder8"
                      size="md"
                      variant="FillIndigoA700"
                      onClick={generate}
                    >
                      Envoyer la demande
                    </Button>
                   
                  </Column>  
                  <Column
                   className=" lg:mt-[40px] xl:mt-[127px] 2xl:mt-[142px] 3xl:mt-[171px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                     >
                      <Text
                    className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]"
                    as="h5"
                    variant="h5"
                  
                  >
                    Demandes de réapprovisionnement du master
                  </Text> 
            <ApproMaster state ={currentUser ? (currentUser.response):null}/>
            </Column>
                  </Column>
            </Column> 
           
            <Modal  title=" Reapprovisionnement" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
            {message && (
           <div className="form-group">
             <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
               {message}
             </div>
           </div>
         )}
            <div class=" p-2 text-center">
          <Img
              src="images/coolupay1.png"
              className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[85%]"
              alt="logoCoolupay"
            />
           <h2> Montant demandé au supermaster :</h2>
            <Text className="Numrodubnf" as="h7" variant="h7">
             {montant} Fcfa
            </Text>
            <h4>Veuillez entrer le code OTP pour valider la demande</h4>
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] ">
          <OTPInput
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
            <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleOk}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8" variant="FillIndigoA700"
                onClick={handleSubmit}
                disabled={successful}>
                {successful && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Confirmer
              </Button>
            </Row>
      </Modal>
      <Modal footer={null} open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1} closable={false}>
      <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handleNavigate7}
                     alt="close"
                   />
                   <Text className="columnclose2 alert alert-success" as="h8" variant="h8">
                      Demande envoyée avec succes
                   </Text>
                   <Img
                     src="images/valide.png"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "20%"
                   />
                  
                 </Column>
      </div>
      </Modal> 
            <Column className="items-center w-[15%]">
              <List
                className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                orientation="vertical"
              >
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorTwo"
                        alt="VectorTwo"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorOne"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de reclamations
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorThree"
                        alt="VectorThree"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorFour"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
              </List>
              <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                <Text className="columnfluxdetransac_two" variant="body2">
                  Flux de reapprovisionnement
                </Text>
                <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                  <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                    <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                      <Row className="items-start justify-between w-[100%]">
                        <Text className="Thirty" variant="body2">
                          30%
                        </Text>
                        <Text className="Forty" variant="body2">
                          40%
                        </Text>
                      </Row>
                      <Text className="columnthirty" variant="body2">
                        30%
                      </Text>
                    </Column>
                  </Stack>
                </Column>
              </Column>
              <Button
                className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                leftIcon={
                  <Img
                    src="images/img_camera_24X24.svg"
                    className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                    alt="camera"
                     onClick={handleNavigate6}
                  />
                }
                shape="RoundedBorder8"
                size="md"
                variant="FillIndigoA700"
                     onClick={handleNavigate6}
              >
                <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                  Appel de fonds
                </div>
              </Button>
            </Column>
          </Row>
        </Column>
      </>
    );
  }
  
};

export default ReapprovisionnementPage;

import axios from "axios";
import authHeader from "./auth-header";


const create = (montant,user, senderId, receiverId)=>{
    const data = JSON.stringify({
        "amount": montant,
        "comment": "Demande d'appel de fonds",
        "user": user,
        "senderId" : senderId,
        "receiverId" : receiverId
      });
      
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/appel`,
        headers: authHeader(),
        data : data
      };
      
      return axios(config)

}
const getAppels_ = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "receiverId": userId,
  });
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/appelsReceiverId`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
  };
  
const getAppels = () => {
    const response = JSON.parse(localStorage.getItem('user'));
    const userId = response.response.user.id
    const data = JSON.stringify({
      "senderId": userId,
    });
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/appels`,
      headers: authHeader(),
      data : data
    };
    return axios(config)
  };
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    create,
    getAppels_,
    getAppels
  };
import React,{useCallback} from "react";
import { createPopper } from "@popperjs/core";
import { Img ,Text } from "../../components";
import { NavLink } from "react-router-dom";
import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Dropdown = ({ color }) => {
 

   const { user: currentUser } = useSelector((state) => state.auth);
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);


  const openDropdownPopover = () => {
    createPopper( popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  // bg colors
  let bgColor;
  color === "white"
    ? (bgColor = "bg-slate-700")
    : (bgColor = "bg-" + color + "-500");
  return (
    <>
      <button
        className={
          "flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
        }
        size="mdIcn"
        variant="icbFillGray200"
        type="button"
        ref={btnDropdownRef}
        onClick={() => {
          dropdownPopoverShow ?
           closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <Img
          src="images/img_settings.svg"
          className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
          alt="settings"
        />
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          (color === "white" ? "bg-white " : bgColor + " ") +
          "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
        }
        style={{ minWidth: "12rem" }}
      >
        <Text className="Envoie_One" >
                       <strong>{currentUser ? (currentUser.response.user.username):null}</strong>  <br/> Master 
        </Text>
        
        
        <div className="h-0 my-2 border border-solid border-t-0 border-slate-800 opacity-25" />
        <NavLink
           to = '/profile'
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent "
          }
        
        >
          Paramètres
        </NavLink>
        <NavLink
          to = '/login'
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " 
          }
          onClick={logOut}
        >
          Déconnexion
        </NavLink>
        
      </div>
    </>
  );
};

export default Dropdown;

import AjoutCaissier from "pages/AjoutCaissier";
import Ajoutsuperviseur from "pages/AjoutSuperviseur";
import AjoutSuperviseurClient from "pages/AjoutSuperviseurClient";
import AppeldeFondsPage from "pages/AppelDeFonds";
import Dashboardmaster from "pages/Dashboardmaster";
import Effectifmaster from "pages/Effectifmaster";
// import InfosCaissier from "pages/InfosCaissier";
// import InfosSuperviseur from "pages/InfosSuperviseur";
import Listecaissiersup from "pages/Listecaissiersup";
import Listecaissiersmaster from "./pages/Listecaissiersmaster";
import ListeUser from "pages/ListeUsers";
//import Listemasters from "pages/Listemasters";
import Listesuperviseursmaster from "pages/Listesuperviseursmaster";
import Listesuperviseursclients from "pages/ListeSuperviseursclients";
import Login from "./pages/Login"
// import ModifierCaissier from "pages/ModifierCaissier";
// import ModifierSuperviseur from "pages/ModifierSuperviseur";
// import ModifierSuperviseurClient from "pages/ModifierSupClient";
import ProfilePage from "pages/Profile";
import ReapproSup from "pages/ReapproSup";
import ReapprovisionnementPage from "pages/Reapprovisionnement";
import Reclamations from "pages/Reclamations";
import Transactions from "pages/Transactions";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboardmaster" element={<Dashboardmaster />} />
        <Route path="/effectifmaster" element={<Effectifmaster />} />
        <Route path="/listesuperviseursmaster" element={<Listesuperviseursmaster />} />
        <Route path="/listesuperviseursclients" element={<Listesuperviseursclients />} />
        <Route path="/ajoutsuperviseur" element={<Ajoutsuperviseur />} />
        <Route path="/ajoutsuperviseurclient" element={<AjoutSuperviseurClient />} />
        <Route path="/ajoutcaissier" element={<AjoutCaissier />} />
     
        {/* <Route path="/modifiercaissier" element={<ModifierCaissier />} /> 
        <Route path="/modifiersuperviseur" element={<ModifierSuperviseur />} /> 
        <Route path="/modifiersupclient" element={<ModifierSuperviseurClient />} />  */}
        <Route path="/listeuser" element={<ListeUser />} />
        <Route path="/listeusersup" element={<Listecaissiersup />} />
        <Route path="/listecaissiersmaster" element={<Listecaissiersmaster />} />
        <Route path="/reapprosup" element={<ReapproSup />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/reclamations" element={<Reclamations />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/reapprovisionnement" element={<ReapprovisionnementPage />} />
        <Route path="/appeldefonds" element={<AppeldeFondsPage />} />
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;

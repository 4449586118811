import ProfileComponent from "components/Profile/Index";
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfilePage = () => {
  const { isLoggedIn } = useSelector(state => state.auth);

  if (!isLoggedIn) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <>
        <ProfileComponent/>
      </>
    );
  }
 
};

export default ProfilePage;

/* eslint-disable no-unused-vars */
import { Button, Input, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, {useRef,useState,useEffect}from 'react';
import CaissierMasterActions from './actions';
import { DatePicker } from 'antd';
import user from "../../services/user";
import StatusActions from './statusActions';
import Highlighter from 'react-highlight-words';
const { RangePicker } = DatePicker;

const ListeCaissiersmaster = () => {
  let locale = {
    emptyText: " Pas de caissiers",
  };
    
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [content, setContent] = useState(""); 
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };
  
  useEffect(() =>  {
    setLoggedIn(false)
    user.getAllCaissiersMaster().then(
      (response) => {
        setContent(response.data.response.user);
        setLoggedIn(true)
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  }, []);
  
  const handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
     setFilteredInfo(filters);
     setSortedInfo(sorter);
   };
//    const [dates, setDates] = useState(null);
//    const [value, setValue] = useState(null);
 
//    function convert(str) {
//      var date = new Date(str),
//        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
//        day = ("0" + date.getDate()).slice(-2);
//      return [date.getFullYear(), mnth, day].join("-");
//    }
 
//    function convertreturnValue1() {
//      if(value !== null){
//      var date = new Date(value[0]._d),
//        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
//        day = ("0" + date.getDate()).slice(-2);
//      return [date.getFullYear(), mnth, day].join("-");
//      }
//    }
 
//    function convertreturnValue2() {
//      if(value !== null){
//      var date = new Date(value[1]._d),
//        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
//        day = ("0" + date.getDate()).slice(-2);
//      return [date.getFullYear(), mnth, day].join("-");
//      }
//    }
//    const disabledDate = (current) => {
//      if (!dates) {
//        return false;
//      }
//      const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
//      const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
//      return !!tooEarly || !!tooLate;
//    };
//    const onOpenChange = (open) => {
//      if (open) {
//        setDates([null, null]);
//      } else {
//        setDates(null);
//      }
//      if(value !== null){
       
//      }
     
//    };
   const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Chercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="link"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Chercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
        title: 'Nom d\'utilisateur ',
        dataIndex: 'username',
        key: 'username',
        ...getColumnSearchProps('username'),
        render: (text, record) => {
            return record?.username
           // return <a onClick={() => { infoClient(record) }}>{record?.name}</a>
        },
       // sorter: (a, b) => a.username.length - b.username.length,
        //sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Addresse',
        dataIndex: 'adresse',
        key: 'adresse', 
        ...getColumnSearchProps('adresse'),
        render: (text, record) => {
            return record?.adresse
        },
    //    sorter: (a, b) => a.adresse.length - b.adresse.length,
      //  sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Numero',
        dataIndex: 'phone',
        key: 'phone',
        ...getColumnSearchProps('phone'),
        render: (text, record) => {
            return record?.phone
        },
    },
     {
         title: 'Etat',
         dataIndex: 'status',
         key: 'block',
         render: (text, record) => {
             return <StatusActions record = {record}/>
         },
      },
    {
        title: 'Actions ',
        key: 'action',
        dataIndex: 'sup_approvement',
        render: (sup_approvement, record) => {
            return <CaissierMasterActions record={record} />
        }
    },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 5}} dataSource={content} loading ={!isLoggedIn} onChange={handleChange} />
    </>
  );

};
export default ListeCaissiersmaster;
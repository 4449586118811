import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { Row,Img,Button } from "./../../components";
import {Input} from 'antd';
import { verifyLoginOtp } from "../../actions/auth";
import { clearMessage } from "../../actions/message";
import Form from "react-validation/build/form";
import OTPInput from "otp-input-react";
import CheckButton from "react-validation/build/button";
import { login } from "../../actions/auth";

export default function Login() {
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
        Champ obligatoire
        </div>
      );
    }
  };
  
  const dispatch = useDispatch();
  

 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successful, setSuccessful] = useState(false);
  
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  
  const [loading, setLoading] = useState(false);
  
  // const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const  [otp, setOtp]  = React.useState ("");   
  //const [isdisabled, setDisabled] = React.useState(false);
 

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  }; 

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          const response = JSON.parse(localStorage.getItem('user'));
          
          setUserId(response.response.user.id) 
          setPhone(response.response.user.phone)
          setIsModalOpen(true);
          setLoading(false);
          dispatch(clearMessage()); // clear message when changing location

        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  let numero = phone.toString()
  const chars = numero.split('');
  
  const  handleSubmit =  (e) => {
    e.preventDefault();

    setSuccessful(true);
    
    dispatch(verifyLoginOtp(userId, otp))
      .then(() => {
        setSuccessful(false);
        navigate("/dashboardmaster");
        window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
        setOtp("")
        
      });
  
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setLoading(false);
    dispatch(clearMessage()); // clear message when changing location
    
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
    dispatch(clearMessage()); // clear message when changing location
    
  };
  
  const isEnabled = username.length > 0 && password.length > 0 ;
 
  return (
    <>
    <figure className="h-screen flex ">
      <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-1">
        <blockquote className="text-2xl font-medium text-center">
        <Img
              src="images/coolupay1.png"
              className="lg:h-[100px] xl:h-[42px] 2xl:h-[100px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[80%]"
              alt="logobbsOne"
            />
        </blockquote>
        
        <div className="m-6">
      <div className="flex items-center mt-3 justify-center">
        <h1 className="text-2xl font-medium  mt-4 mb-2">
        Connectez-vous à votre compte Master
        </h1>
      </div>
      <Form  onSubmit={handleLogin} ref={form}>

      {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <span style={{color:"red"}}>*</span> 
        <label className="text-left">Nom d'utilisateur :</label>
        <input
          type="text"
          name="username"
          value={username}
          onChange={onChangeUsername}
          validations={[required]}
          className="w-full p-2  border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
          
        />
          <span style={{color:"red"}}>*</span> 
        <label>Mot de passe :</label>
        <Input.Password type="password"
          name="password"
          value={password}
          onChange={onChangePassword}
          validations={[required]}
          className="w-full p-2  border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"/>

        <div className="form-group">
            <button 
            className={"bg-blue-700 hover:bg-blue-500 py-2 px-4 text-md text-white rounded border border-blue focus:outline-none focus:border-black" }  
            disabled={!isEnabled}>
              
               {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Connexion</span>
            </button>
          </div>

          
           <CheckButton style={{ display: "none" }} ref={checkBtn} /> 
      </Form>
      
    </div>
        
      </div>
    </figure>

<Modal  title="" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
{/* <blockquote className="text-2xl font-medium text-center">
        <Img
              src="images/sms.svg"
              className="lg:h-[70px] xl:h-[88px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[75%]"
              alt="logobbsOne"
            />
</blockquote> */}
{message && (
      <div className="form-group">
        <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
          {message}
        </div>
      </div>
    )} 
    
    <div class=" p-2 text-center"> 
          <Img 
              src="images/coolupay1.png"
              className="lg:h-[4100x] xl:h-[42px] 2xl:h-[100px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[80%]"
              alt="logobbsOne"
            />
            {/* <h4>Please enter the one time password <br/> to verify your account</h4> */}
            <h4>Veuillez entrer le mot de passe à usage unique <br/> pour vérifier votre compte</h4>
             <div>
                 {/* <span>A code has been sent to</span> <strong>*******{chars[6]}{chars[7]}{chars[8]}</strong>  */}
                 <span>Un code a été envoyé à</span> <strong>*******{chars[6]}{chars[7]}{chars[8]}</strong> 
              </div>
             
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] "> 
          <OTPInput   
             value={otp} 
             inputStyles={{width: "20%",
             height: "30px",
             textAlign: "center",
             fontSize: "14px",
             fontWeight: "bold"}}
              onChange={setOtp} 
              inputStyle ="text-center"
              autoFocus 
              OTPLength={4} 
              otpType="number" disabled={false}  />
             
          </div>
          </div>
              {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
          <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
            <Button
              className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
              
              shape="RoundedBorder8"
              onClick={handleOk}
            >
              Annuler
            </Button>
            <Button
              className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
              shape="RoundedBorder8"
              variant="FillIndigoA700"
              onClick={handleSubmit}
              disabled={successful}
              >
              {successful && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Confirmer
            </Button>
          </Row>
         
    
  
       </Modal> 
       </> 
  );
}
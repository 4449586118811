import axios from "axios";
import authHeader from "./auth-header";

const create = (montant,user,senderId,receiverId)=>{
    const data = JSON.stringify({
        "amount": montant,
        "comment": "Demande de reapprovisonnement",
        "user": user,
        "senderId": senderId,
        "receiverId":receiverId
      });
      
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/reapprovisionnement`,
        headers: authHeader(),
        data : data
      };
      
      return axios(config)

};

const getAllReap = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "senderId": userId,
  });
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/reapprovisionnements`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

const getAllReap_ = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "receiverId": userId,
  });
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/reapprovisionnementReceiverId`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
  };
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    create,
    getAllReap,
    getAllReap_
  };
import {Space} from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import { Alert} from 'antd';
export default function StatusActions(props) {
    const status = props?.record?.status
    const [loading] = useState(false)
   // console.log(status)
    if (loading) return <Button type="primary" shape="circle" loading />
    if (status === 'PENDING') return (
        <Alert style={{border:0}} message="EN COURS" type="warning" showIcon />
    )
    if (status === 'FAILED') return (
        <Alert style={{border:0}} message="REJETTÉ" type="error" showIcon />
    )
    if (status === 'SUCCESS') return (
        <Alert style={{border:0}} message="VALIDÉ" type="success" showIcon />
    )
    return (
        <Space size='middle' >
        </Space>
    )
}

import { VALIDE_SUCCESS, VALIDE_FAIL, SET_MESSAGE,} from "./types";
import user from "../services/user";

  export const verifyPhoneOtp = (userId, otp) => (dispatch) => {
    return user.verifyPhoneOtp(userId, otp).then(
      (data) => {
        dispatch({
          type: VALIDE_SUCCESS,
          payload: { data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

    export const update = (userId,firstName,lastName,email,phone) => (dispatch) => {
      return user.updateProfile(userId,firstName,lastName,email,phone).then(
        (response) => {
          dispatch({
            type: VALIDE_SUCCESS,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: response.data.message,
          });
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: VALIDE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      );
    };
    
    export const updatePwd = (userId,password, newPassword) => (dispatch) => {
      return user.updatePwd(userId,password, newPassword).then(
        (response) => {
          dispatch({
            type: VALIDE_SUCCESS,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: response.data.message,
          });
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: VALIDE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      );
    };

    export const createCaissier = (firstName,lastName,username,adresse,phone,CNI,email,created_by,img1,img2) => (dispatch) => {
      return user.createCaissier(firstName,lastName,username,adresse,phone,CNI,email,created_by,img1,img2).then(
        (response) => {
          dispatch({
            type: VALIDE_SUCCESS,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: response.data.message,
          });
          return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: VALIDE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      );
    };
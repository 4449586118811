/* eslint-disable no-unused-vars */
import { Space, Table } from 'antd';
import React, { useState,useEffect}from 'react';
import CaissiersActions from './CaissiersActions';
import user from "../../services/user"
import { DatePicker } from 'antd';
import StatusActions from './statusActions';
const { RangePicker } = DatePicker;

const ListeCaissiers = (props) => {
  let locale = {
    emptyText: "Pas d'utilisateurs",
  };
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [content, setContent] = useState("");
  const id = props.record.id
  
  useEffect(() =>  {
    setLoggedIn(false)
    user.getAllCaissiers(id).then(
      (response) => {
        setContent(response.data.response.user);
        setLoggedIn(true)
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  }, [id]);
  
  const handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
     setFilteredInfo(filters);
     setSortedInfo(sorter);
   };
   const [dates, setDates] = useState(null);
   const [value, setValue] = useState(null);
 
   function convert(str) {
     var date = new Date(str),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
   }
 
   function convertreturnValue1() {
     if(value !== null){
     var date = new Date(value[0]._d),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
     }
   }
 
   function convertreturnValue2() {
     if(value !== null){
     var date = new Date(value[1]._d),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
     }
   }
   const disabledDate = (current) => {
     if (!dates) {
       return false;
     }
     const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
     return !!tooEarly || !!tooLate;
   };
   const onOpenChange = (open) => {
     if (open) {
       setDates([null, null]);
     } else {
       setDates(null);
     }
     if(value !== null){
       
     }
     
   };
  const columns = [
    {
        title: 'Nom d\'utilisateur ',
        dataIndex: 'username',
        key: 'username',
        render: (text, record) => {
            return record?.username
           // return <a onClick={() => { infoClient(record) }}>{record?.name}</a>
        },
        //sorter: (a, b) => a.username.length - b.username.length,
        //sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Addresse',
        dataIndex: 'adresse',
        key: 'adresse',
        render: (text, record) => {
            return record?.adresse
        },
       // sorter: (a, b) => a.adresse.length - b.adresse.length,
        //sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Numero',
        dataIndex: 'phone',
        key: 'phone',
        render: (text, record) => {
            return record?.phone
        },
    },
     {
         title: 'Etat',
         dataIndex: 'status',
         key: 'block',
         render: (text, record) => {
             return <StatusActions record = {record}/>
         },
      },
    {
        title: 'Actions ',
        key: 'action',
        dataIndex: 'sup_approvement',
        render: (sup_approvement, record) => {
            return <CaissiersActions record={record} />
        }
    },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 5}} dataSource={content} loading ={!isLoggedIn} onChange={handleChange} />
    </>
  );

};
export default ListeCaissiers;
import { Space, Modal, Button } from 'antd';
import React, { useState } from 'react';
//import jsPDF from 'jspdf';
//import html2canvas from 'html2canvas';
import { InfoCircleTwoTone   } from '@ant-design/icons';
import moment from "moment"

// function printDocument() {
//     const input = document.getElementById('divToPrint1');
//     html2canvas(input)
//       .then((canvas) => {
//         //const imgData = canvas.toDataURL('image/png');
//         const pdf = new jsPDF();
//         //pdf.addImage(imgData, 'JPEG', 0, 0);
//          //pdf.output('dataurlnewwindow');
//         pdf.save("recu.pdf");
//       })
//     ;
//   }

function showInfosTrx(record) {
    Modal.info({
        title: 'Details de la transaction',
        content: (
            <div>
            <div id="divToPrint1" style={{marginTop: 10 ,backgroundColor: '#f5f5f5'
            }}>
               
               <p>Recu : {record.type+" "+ record.service}</p> 
                <p>Numero : {record.numero}</p>
                <p>Montant : {record.montant}</p>
                <p>Date :  {moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss")}</p>
                <p>Etat :  {record.status}</p>
                <br/>
               
            </div>
            <br/>
            <br/>
             {/* <Button
               icon={<FcPrint size="25px" />} 
               onClick={() => { printDocument() }}/> */}
             </div>
        )
    });
}
export default function BankerActions(props) {
    
    
    const [loading] = useState(false)
  
    if (loading) return <Button type="primary" shape="circle" loading />
    return (
        
        <Space size="small">
            <Button 
                    icon={<InfoCircleTwoTone  size="25px"  />}
                     type="text" onClick={() => { showInfosTrx(props?.record) }} 
            />
            
         </Space>  
        
        
    )
}
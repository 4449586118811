/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { Space, Modal } from 'antd';
import React, { useState,useEffect } from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone  } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import updatesolde from "../../actions/updatesolde";
import updatereap from "../../actions/updateReap";
import { verifyPhoneOtp } from 'actions/user';
import { Row, Text, Column, Img, Button } from "components";
import user from "../../services/user";
import OTPInput from "otp-input-react";
import { useDispatch,useSelector } from 'react-redux';


function actionsapprovisionnement(record) {
    
    Modal.info({
        title: 'Actions Reapprovisionnement',
        content: (
            <p style={{ marginTop: 10 }}>
                <p>Nom :  {record.name}</p>
                <p>Date :  {record.date}</p>
                <p>Etat :  {record.etat}</p>
                <p>Montant :  {record.montant}</p>
                <br />
                {/* <DemandeProgression step={step} /> */}
            </p>
        )
    });
}

export default function ApprosActions(props) {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  const state = props?.record
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen0, setIsModalOpen0] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [balance, setBalance] = useState("");
  const  [montant, setMontant]  = React.useState ( "" );
  const  [otp, setOtp]  = React.useState ("");
  const response = JSON.parse(localStorage.getItem('user'));
  const { message } = useSelector(state => state.message);
  const [content, setContent] = useState("");
  const userId = currentUser.response.user.id
  const receiverId = state.senderId;
  const senderId  = state.receiverId;
  const  solde = state.amount;

  const navigate = useNavigate();
 
  const generate = () => {
        setIsModalOpen(true);
      };

      useEffect(() => {
        user.getBalance(userId).then(
          (response) => {
            setBalance(response.data);
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
              setBalance(_content);
          }
        );
        },[] ); 

      const handleSubmit =  (e) => {
        e.preventDefault();
        setSuccessful(true);
        dispatch(verifyPhoneOtp(userId, otp))
          .then(() => {
           
            if (solde >balance.solde) {
              setIsModalOpen(false);
              setIsModalOpen1(true);
            } else {
              setIsModalOpen(false);
              setIsModalOpen3(true);
              dispatch(updatesolde(receiverId,senderId,solde))
              dispatch(updatereap(state.id,state.montant,state.user,"SUCCESS",state.senderId,state.receiverId))
              navigate("/reapprosup");
              
            }
          })
          .catch(() => {
            setSuccessful(false);
          });
       
      };

      function handleNavigate0() {
        window.location.reload();
      }

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSubmit00 =  (e) => {
    e.preventDefault();
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        dispatch(updatereap(state.id,state.montant,state.user,"FAILED",state.senderId,state.receiverId))
        setIsModalOpen0(false);
        setIsModalOpen2(true);       
      })
      .catch(() => {
        setSuccessful(false);
      });
  };
  const showModal0 = () => {
    setIsModalOpen0(true);
  };
  const handleOk0 = () => {
    setIsModalOpen0(false);
  };
  const handleCancel0 = () => {
    setIsModalOpen0(false);
  };  
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  }; 
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  }; 
    const [] = useState(props?.record?.appros_approvement?.approvement)
    const [loading] = useState(false)
    if (loading) return <Button type="primary" shape="circle" loading />
    const status = props?.record?.status
    if (status === 'SUCCESS' ) return null
    if (status === 'FAILED' ) return null
    
    return (
        <Space size="small">
            <button  onClick={generate}> <CheckCircleTwoTone twoToneColor="#52c41a" size="25px" title="Approuver Demande"/> </button>
            <Modal  title="Verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
            {message && (
           <div className="form-group">
             <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
               {message}
             </div>
           </div>
         )}
            <div class=" p-2 text-center">
          <Img
              src="images/coolupay1.png"
              className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[85%]"
              alt="logoCoolupay"
            />
         <h2> Montant demandé par le superviseur :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
            <h4>Veuillez entrer le code OTP pour traiter la demande</h4>
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] ">
          <OTPInput
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
            <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleOk}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8" variant="FillIndigoA700"
                onClick={handleSubmit}
                disabled={successful}>
                {successful && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Confirmer
              </Button>
            </Row>
            </Modal>

            <Modal footer={null} open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1} closable={false}>
            <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handleNavigate0}
                     alt="close"
                   />
                 <h2> Montant demandé par le superviseur :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
                      <h4 className="columnclose2 alert alert-danger" as="h8" variant="h8">Votre solde est insuffisant pour valider la demande </h4>
                   <Img
                     src="images/invalide.webp"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "20%"
                   />
                 </Column>
      </div>
            </Modal>

       <Modal footer={null} open={isModalOpen3} onOk={handleOk3} onCancel={handleCancel3} closable={false}>
           <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handleNavigate0}
                     alt="close"
                   />
                 <h2> Montant demandé par l'utilisateur :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
                      <h4 className="columnclose2 alert alert-success" as="h8" variant="h8"> Demande validée avec succès votre compte vient d'être
 débité de {solde} Fcfa </h4>
                   <Img
                     src="images/valide.png"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "20%"
                   />
                   {/* <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                           {props.message}
                     </Text> */}
                 </Column>
      </div>
       </Modal>


    <button  onClick={showModal0}><CloseCircleTwoTone twoToneColor="#eb2f96" size="25px" title="Annuler Demande"/> </button>
        <Modal  title="Verification code OTP" footer={null} open={isModalOpen0} onOk={handleOk0} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel0}>
        {message && (
           <div className="form-group">
             <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
               {message}
             </div>
           </div>
         )}
            <div class=" p-2 text-center">
          <Img
              src="images/coolupay1.png"
              className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[85%]"
              alt="logoCoolupay"
            />
         <h2> Montant demandé par le superviseur :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
            <h4>Veuillez entrer le code OTP pour traiter la demande</h4>
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] ">
          <OTPInput
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
            <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8" variant="FillIndigoA700"
                onClick={handleOk0}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleSubmit00}
                disabled={successful}>
                {successful && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Confirmer
              </Button>
            </Row>
    </Modal>  

    <Modal footer={null} open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2} closable={false}>
    <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handleNavigate0}
                     alt="close"
                   />
                   <h2> Montant demandé par l'utilisateur :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
                      <h4 className="columnclose2 alert alert-danger" as="h8" variant="h8">  Demande rejetée </h4>    
                   <Img
                     src="images/invalide.webp"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "20%"
                   />
                   {/* <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                           {props.message}
                     </Text> */}
                 </Column>
      </div>
    </Modal>

        </Space>
    )
}
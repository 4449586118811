/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Space, Table } from 'antd';
import React, { useState,useEffect} from 'react';
import user from "../../services/user";
import ReclamationsActions from './ReclamationsActions';
//import DatePicker from "react-datepicker";
import moment from "moment"
import ServiceActions from './ServiceActions';
import StatusActions from './StatusActions';

//const { RangePicker } = DatePicker;

const ListeReclamations = () => {
  let locale = {
    emptyText: 'Pas de reclamations',
  };
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [content, setContent] = useState("");
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
   //console.log("id > ",userId);
   const startTime = new Date();
   const maxTime = 3* 1000; // 60 seconds
  //  setInterval(() => {
  //   checkExpired();
  //  }, 300);

function checkExpired() {
  const curTime = new Date();

  const timeDifference = Math.abs(curTime.getTime() - startTime.getTime());

  if (timeDifference > maxTime) {
    console.log("time up");
    setLoggedIn(false)
  }else{
    console.log("time up 2");
  }
}
  useEffect(() => {
    setLoggedIn(false)

    user.getAllReclamations(userId).then(
      (response) => {
        setContent(response.data.response.reclamations);
        setLoggedIn(true)
        
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          return _content
      }
    );
  }, []);
  
  const handleChange = (pagination, filters, sorter) => {
   // console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
   };
  // const [dates, setDates] = useState(null);
  // const [value, setValue] = useState(null);

  // function convert(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  // }

  // function convertreturnValue1() {
  //   if(value !== null){
  //   var date = new Date(value[0]._d),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  //   }
  // }

  // function convertreturnValue2() {
  //   if(value !== null){
  //   var date = new Date(value[1]._d),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  //   }
  // }
  // const disabledDate = (current) => {
  //   if (!dates) {
  //     return false;
  //   }
  //   const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
  //   const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
  //   return !!tooEarly || !!tooLate;
  // };
  // const onOpenChange = (open) => {
  //   if (open) {
  //     setDates([null, null]);
  //   } else {
  //     setDates(null);
  //   }
  //   if(value !== null){
      
  //   }
    
  // };
  const columns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      render: (reclamations_approvement, record) => {
        return <ServiceActions record={record} />
    }
    },
    // {
    //     title: 'Nom',
    //     dataIndex: 'name',
    //     key: 'name',
    //     sorter: (a, b) => a.name.length - b.name.length,
    //     sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
    //     ellipsis: true,
    //     render: (text, record) => {
    //       return record?.user
    //        },
    // },
    
    {
        title: 'Montant',
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => a.amount.length - b.amount.length,
        sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text, record) => {
          return record?.amount
           },
    }, 
    {
      title: 'Date',
      dataIndex: 'transactions',
      key: 'date',
    
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY H:mm:ss");
         },
    },
    {
        title: 'Etat',
        dataIndex: 'status',
        key: 'status',
        render: (reclamations_approvement, record) => {
          return <StatusActions record={record} />
      }
    },
   
    {
        title: 'Actions ',
        key: 'action',
        dataIndex: 'sup_approvement',
        render: (reclamations_approvement, record) => {
            return <ReclamationsActions record={record} />
        }
    },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
      </Space>
      <Table locale={locale} dataSource={content} loading ={!isLoggedIn} columns={columns} pagination={{ pageSize: 5}} onChange={handleChange} />
    </>
  );
};
export default ListeReclamations;
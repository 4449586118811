/* eslint-disable no-unused-vars */
import { Space, Table,Input } from 'antd';
import React, {useRef,useState,useEffect} from 'react';
import reappro from "../../services/reappro";
import DatePicker from "react-datepicker";
import ApprosActions from "./actions"
import StatusActions from './statusActions';
import moment from "moment";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {  Button } from "components";
const { RangePicker } = DatePicker;

const ApproSup = (props) => { let locale = {
  emptyText: " Pas de reapprovisionnements",
};
  
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [content, setContent] = useState("");
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    setLoggedIn(false)
   // if(props.userId) {
    reappro.getAllReap_().then(
      (response) => {
        setContent(response.data.response.reapprovisionnement);
        setLoggedIn(true)
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          
      }
    );
   // }
  }, []);
   
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };
  const handleChange = ( filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
     setFilteredInfo(filters);
     setSortedInfo(sorter);
   };
   const [dates, setDates] = useState(null);
   const [value, setValue] = useState(null);
 
   function convert(str) {
     var date = new Date(str),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
   }
 
   function convertreturnValue1() {
     if(value !== null){
     var date = new Date(value[0]._d),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
     }
   }
 
   function convertreturnValue2() {
     if(value !== null){
     var date = new Date(value[1]._d),
       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
       day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
     }
   }
   const disabledDate = (current) => {
     if (!dates) {
       return false;
     }
     const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
     return !!tooEarly || !!tooLate;
   };
   const onOpenChange = (open) => {
     if (open) {
       setDates([null, null]);
     } else {
       setDates(null);
     }
     if(value !== null){
       
     }
     
   };
   const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Chercher
          </button>
          <button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </button>
          <button
            type="link"
            size="small" 
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
     // ...getColumnSearchProps('nom'), 
      // sorter: (a, b) => a.amount.length - b.amount.length,
      // sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return record?.user
         },
    },
    {
        title: 'Montant',
        dataIndex: 'amount',
        key: 'amount',
        // sorter: (a, b) => a.amount.length - b.amount.length,
        // sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text, record) => {
          return record?.amount
           },
    },
    
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY  H:mm:ss");

         },
      // //   filters :[
      // //   {
      // //     text:<RangePicker
      // //             value={dates || value}
      // //             disabledDate={disabledDate}
      // //             onCalendarChange={(val) => setDates(val)}
      // //             onChange={(val) => setValue(val)}
      // //             onOpenChange={onOpenChange}
      // //          />,
      // //         value: convertreturnValue1()
      // //   }
      // // ],
      // // filteredValue: filteredInfo.date || null,
      // // onFilter: (value, record) => record.date.includes(value),
      // // sorter: (a, b) => a.date.length - b.date.length,
      // sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      // ellipsis: true,
    },
    {
      title: 'Etat',
      dataIndex: 'statut',
      key: 'statut',
      filters: [
        {
          text: 'Pending',
          value: 'Pending',
        }, 
          {
            text: 'Failed',
            value: 'Failed',
          },
        {
          text: 'Successs',
          value: 'Success',
        },
      ],
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.address.includes(value),
      render: (text, record) => {
        return <StatusActions record = {record}/>
         },
    },
    
    {
      title: 'Actions ',
      key: 'action',
      width:'13%',
      dataIndex: 'sup_approvement',
      render: (reclamations_approvement, record) => {
          return <ApprosActions record={record} />
      }
  },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 5}} loading ={!isLoggedIn} dataSource={content} onChange={handleChange} />
    </>
  );
};
export default ApproSup;
import React,{useState,useEffect} from "react";
import { Column, Row, Button, Text, List, Img, Stack,Line } from "components";
import Sidebar from "components/Sidebar/Sidebar";
import { useNavigate, Navigate } from "react-router-dom";
import Dropdown from "components/Dropdown";
import user from "../../services/user";
import { useSelector } from "react-redux";


const EffectifmasterPage = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector(state => state.auth);
 // const username = response.response.user.username
  const [supCom, setSupCom] = useState("");
  const [supclients, setSupClients] = useState("");
  const [caissiers, setCaissiers] = useState("");

  useEffect(() => {
    if (currentUser) {
    user.nbSupCom(currentUser.response.user.id).then(
      (response) => {
        setSupCom(response.data.count);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setSupCom(_content);
      }
    );
    user.nbSupClients(currentUser.response.user.id).then(
      (response) => {
       setSupClients(response.data.count);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setSupClients(_content);
      }
    ); 
    user.nbCaissiers(currentUser.response.user.id).then(
      (response) => {
       setCaissiers(response.data.count);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setCaissiers(_content);
      }
    );}
    }, );
   

  function handleNavigate1() {
    navigate("/listesuperviseursmaster");
  }
  function handleNavigate2() {
    navigate("/listesuperviseursclients");
  }
  function handleNavigate3() {
    navigate("/listecaissiersmaster");
    }
    function handleNavigate11() {
      navigate("/reapprovisionnement");
      }
      function handleNavigate5() {
        navigate("/dashboardmaster");
        }

        if (!isLoggedIn) {
          return <Navigate replace to="/login" />;
        } else {return (          
          <>
            <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
              <Row className="justify-between w-[98%]">
              <Sidebar className="w-[20%]" />
              
                <Column className="bg-white_A700 items-center lg:py-[5px] xl:py-[6px] 2xl:py-[7px] 3xl:py-[9px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
                <Row className="items-start w-[92%]">
                      <Button
                        className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center mt-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                        size="smIcn"
                        variant="icbFillGray201"
                      >
                        <Img
                          src="images/img_arrowleft.svg"
                          className="flex items-center justify-center"
                          onClick={handleNavigate5} 
                          alt="arrowleft"
                        />
                      </Button>
                      <Text className="Effectif" as="h5" variant="h5">
                        Effectif
                      </Text>
                    </Row>
                    <Line className="bg-black_900_19 h-[1px] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]" />
                    <List
                    className="font-montserrat lg:gap-[16px] xl:gap-[20px] 2xl:gap-[22px] 3xl:gap-[27px] grid grid-cols-2 min-h-[auto] lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[100%]"
                    orientation="horizontal" >
            
                   <Column className="bg-white_A700 items-center justify-end lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius12 shadow-bs1 w-[100%]">
                      <Column className="justify-start 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] w-[100%]">
                        <Row className="items-end lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[41%]">
                        <Text
                            as = "h4"
                            variant="h4"
                            className="text-indigo_A700"
                            >
                              <span >
                           {new Intl.NumberFormat('de-DE').format(supCom)} 
                        </span> Superviseurs Commerciaux
                            </Text>
                        </Row>
      
                        <Button
                          className="common-pointer font-semibold lg:mt-[37px] xl:mt-[47px] 2xl:mt-[53px] 3xl:mt-[64px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                          onClick={handleNavigate1}
                          shape="RoundedBorder8"
                          size="md"
                          variant="FillIndigoA700"
                        >
                          Gérer les superviseurs commerciaux
                        </Button>
                      </Column>
                    </Column> 
      
                    <Column className="bg-white_A700 items-center justify-end lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius12 shadow-bs1 w-[100%]">
                      <Column className="justify-start 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] w-[100%]">
                        <Row className="items-end lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[41%]">
                        <Text
                            as = "h4"
                            variant="h4"
                            className="text-indigo_A700"
                            >
                              <span >
                           {new Intl.NumberFormat('de-DE').format(supclients)} 
                        </span> Superviseurs Clients
                            </Text>
                        </Row>
      
                        <Button
                          className="common-pointer font-semibold lg:mt-[37px] xl:mt-[47px] 2xl:mt-[53px] 3xl:mt-[64px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                          onClick={handleNavigate2}
                          shape="RoundedBorder8"
                          size="md"
                          variant="FillIndigoA700"
                        >
                          Gérer les superviseurs clients
                        </Button>
                      </Column>
                    </Column> 
      
                    <Column className="bg-white_A700 items-center justify-end lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius12 shadow-bs1 w-[100%]">
                      <Column className="justify-start 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] w-[100%]">
                        <Row className="items-end lg:mt-[19px] xl:mt-[24px] 2xl:mt-[27px] 3xl:mt-[33px] w-[41%]">
                        <Text
                            as = "h4"
                            variant="h4"
                            className="text-indigo_A700"
                            >
                              <span >
                           {new Intl.NumberFormat('de-DE').format(caissiers)} 
                        </span>  Caissiers 
                            </Text>
                        </Row>
      
                        <Button
                          className="common-pointer font-semibold lg:mt-[37px] xl:mt-[47px] 2xl:mt-[53px] 3xl:mt-[64px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                          onClick={handleNavigate3}
                          shape="RoundedBorder8"
                          size="md"
                          variant="FillIndigoA700"
                        >
                          Gérer les caissiers
                        </Button>
                      </Column>
                    </Column> 
                </List>
                </Column>
                
                <Column className="items-center w-[15%]">
                  <Row className="bg-white_A700 items-center lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                 
                    <Dropdown/>
                    <Text className="rowsettings" variant="body1">
                     
                <strong>{currentUser ? (currentUser.response.user.username):null}</strong>
                    </Text>
                    <Img
                      src="images/img_ellipse1.png"
                      className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                      alt="EllipseOne"
                    />
                  </Row>
                  <List
                    className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                    orientation="vertical"
                  >
                    <Column className="listfluxdetransac">
                      <Column className="justify-start mt-[2px] w-[94%]">
                        <Text
                          className="font-medium text-black_900 w-[auto]"
                          variant="body2"
                        >
                          Flux de transactions
                        </Text>
                        <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                          <Img
                            src="images/img_vector2.png"
                            className="VectorTwo"
                            alt="VectorTwo"
                          />
                          <Img
                            src="images/img_vector1.svg"
                            className="VectorOne"
                            alt="VectorOne"
                          />
                        </Stack>
                        <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            lun
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            mar
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            mer
                          </Text>
                          <Text
                            className="mt-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            jeu
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            ven
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            sam
                          </Text>
                          <Text
                            className="text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            dim
                          </Text>
                        </Row>
                      </Column>
                    </Column>
                    <Column className="listfluxdetransac">
                      <Column className="justify-start mt-[2px] w-[94%]">
                        <Text
                          className="font-medium text-black_900 w-[auto]"
                          variant="body2"
                        >
                          Flux de reclamations
                        </Text>
                        <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                          <Img
                            src="images/img_vector2.png"
                            className="VectorThree"
                            alt="VectorThree"
                          />
                          <Img
                            src="images/img_vector1.svg"
                            className="VectorOne"
                            alt="VectorFour"
                          />
                        </Stack>
                        <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                          <Text
                            className="mt-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            lun
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            mar
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            mer
                          </Text>
                          <Text
                            className="mt-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            jeu
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            ven
                          </Text>
                          <Text
                            className="my-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            sam
                          </Text>
                          <Text
                            className="mb-[1px] text-bluegray_100 w-[auto]"
                            as="h1"
                            variant="h1"
                          >
                            dim
                          </Text>
                        </Row>
                      </Column>
                    </Column>
                  </List>
                  <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                    <Text className="columnfluxdetransac_two" variant="body2">
                      Flux de reapprovisionnement
                    </Text>
                    <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                      <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                        <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                        <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                        <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                        <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                          <Row className="items-start justify-between w-[100%]">
                            <Text className="Thirty" variant="body2">
                              30%
                            </Text>
                            <Text className="Forty" variant="body2">
                              40%
                            </Text>
                          </Row>
                          <Text className="columnthirty" variant="body2">
                            30%
                          </Text>
                        </Column>
                      </Stack>
                    </Column>
                  </Column>
                  <Button
                    className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                    leftIcon={
                      <Img
                        src="images/img_camera_24X24.svg"
                        className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                        alt="camera"
                      />
                    }
                    shape="RoundedBorder8"
                    size="md"
                    variant="FillIndigoA700"
                  >
                    <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]"
                     onClick={handleNavigate11}>
                      Réapprovisionner
                    </div>
                  </Button>
                </Column>
              </Row>
            </Column>
          </>
        ); }
  
};

export default EffectifmasterPage;

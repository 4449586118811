import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE,VALIDE_SUCCESS,VALIDE_FAIL} from "./types";
import auth from "services/auth";
  
  export const login = (username, password) => (dispatch) => {
    return auth.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  export const verifyLoginOtp = (userId, otp) => (dispatch) => {
    return auth.verifyLoginOtp(userId, otp).then(
      (data) => {
        dispatch({
          type: VALIDE_SUCCESS,
          payload: { user:data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: VALIDE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const logout = () => (dispatch) => {
    auth.logout();
  
    dispatch({
      type: LOGOUT,
    });
  };
import {VALIDE_SUCCESS,VALIDE_FAIL,SET_MESSAGE,SET_STATUS} from "./types";
import reappro from "../services/reappro";

  export const create = (montant, user,senderId,receiverId) => (dispatch) => {
    return reappro.create(montant, user,senderId,receiverId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
          payload: { response },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        dispatch({
          type: SET_STATUS,
          payload: response.data.status,
        });
        
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
          const status = error.response.status
  
          dispatch({
            type: VALIDE_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          dispatch({
            type: SET_STATUS,
            payload:status,
          });
  
        return Promise.reject();
      }
    );
  };
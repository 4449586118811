import React, {useState} from "react";
import { Column, Row, Button, Img, Text, Line, List, Stack } from "components";
import Sidebar from "components/Sidebar/Sidebar";
import { useNavigate, Navigate } from "react-router-dom";
import ListeCaissiersmaster from "components/ListeCaissierMaster";
import Dropdown from "components/Dropdown";
import { Modal } from 'antd';
import { verifyPhoneOtp } from "../../actions/user";
import OTPInput from "otp-input-react";
import { useDispatch,useSelector } from 'react-redux';


const ListeCaissiersMasterPage = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector(state => state.auth);
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const  [otp, setOtp]  = React.useState ("");
  const { message } = useSelector(state => state.message);
 // const { status } = useSelector(state => state.status);
 
    function handleNavigate2() {
      navigate("/ajoutcaissier");
    }
    function handleNavigate() {
      navigate("/effectifmaster");
    }
    function handleNavigate3() {
      navigate("/reapprovisionnement");
    }
    const generate = () => {
          setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const handleSubmit =  (e) => {
      e.preventDefault();
      setSuccessful(true); 
       dispatch(verifyPhoneOtp(currentUser.response.user.id, otp))
        .then(() => {
          navigate("/ajoutcaissier");
          // window.location.reload();
        })
        .catch(() => { 
        //   if(status === 401){
            
        //   navigate("/");
        //   logout()
        // }
       
        });
    };
    if (!isLoggedIn) {
      return <Navigate replace to="/login" />;
    } else {
      return (
        <>
          <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
            <Row className="justify-between w-[98%]"> <Sidebar className="w-[20%]" />
              <Column className="bg-white_A700 items-center py-[4px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs1 w-[63%]">
                <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                  <Row className="items-start w-[92%]">
                    <Button
                      className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center my-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                      size="smIcn"
                      variant="icbFillGray201"
                    >
                      <Img
                        src="images/img_arrowleft.svg"
                        className="flex items-center justify-center"
                        alt="arrowleft"
                      onClick={handleNavigate}
                      />
                    </Button>
                    <Text className="superviseurs" as="h5" variant="h5"
                    
                    onClick={handleNavigate2}
                    >
                      Caissiers
                    </Text>
                  </Row>
                  <Line className="bg-black_900_19 h-[1px] lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[100%]" />
                  <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[93%]">
                    <Row className="items-start w-[99%]">
                      
                      <Text
                       className="text-indigo_A700"
                       as="h3"
                       variant="h3"
                      >
                        Total Caissiers
                      </Text>
                      
                      <Button
                        className="2xl:ml-[257px] 3xl:ml-[308px] flex items-center justify-center lg:ml-[200px] text-center w-[38%] xl:ml-[228px]"
                        
                        leftIcon={
                          <Img
                            src="images/img_plus.svg"
                            className="text-center lg:w-[21px] lg:h-[22px] lg:mr-[10px] xl:w-[27px] xl:h-[28px] xl:mr-[12px] 2xl:w-[30px] 2xl:h-[31px] 2xl:mr-[14px] 3xl:w-[36px] 3xl:h-[37px] 3xl:mr-[17px]"
                            alt="plus"
                            onClick={generate}
                          />
                        }
                        shape="RoundedBorder8"
                        size="md"
                        variant="FillIndigoA700"
                        onClick={generate}
                      >
                    Caissier
                      </Button>
                      <Modal  title="Verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
                      {message && (
           <div className="form-group">
             <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
               {message}
             </div>
           </div>
         )}
            <div class=" p-2 text-center">
          <Img
              src="images/coolupay1.png"
              className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[85%]"
              alt="logoCoolupay"
            />
            <h4>Veuillez entrer le code OTP pour ajouter un caissier</h4>
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] ">
          <OTPInput
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
            <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleOk}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8" variant="FillIndigoA700"
                onClick={handleSubmit}
                disabled={successful}>
                {successful && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Confirmer
              </Button>
            </Row>
        </Modal>
                    </Row>
                    <Row>
                      <ListeCaissiersmaster />
                    </Row>
                  </Column>
                </Column>
              </Column>
              <Column className="items-center w-[15%]">
                <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                 <Dropdown/>
                  <Text
                    className="font-semibold text-black_900 w-[auto]"
                    variant="body1"
                  >
                  <strong>{currentUser ? (currentUser.response.user.username):null}</strong>
                  </Text>
                  <Img
                    src="images/img_ellipse1.png"
                    className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                    alt="EllipseOne"
                  />
                </Row>
                <List
                  className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                  orientation="vertical"
                >
                  <Column className="listfluxdetransac">
                    <Column className="justify-start mt-[2px] w-[94%]">
                      <Text
                        className="font-medium text-black_900 w-[auto]"
                        variant="body2"
                      >
                        Flux de transactions
                      </Text>
                      <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                        <Img
                          src="images/img_vector2.png"
                          className="VectorTwo"
                          alt="VectorTwo"
                        />
                        <Img
                          src="images/img_vector1.svg"
                          className="VectorOne"
                          alt="VectorOne"
                        />
                      </Stack>
                      <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                        <Text
                          className="mt-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          lun
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          mar
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          mer
                        </Text>
                        <Text
                          className="mt-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          jeu
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          ven
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          sam
                        </Text>
                        <Text
                          className="mb-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          dim
                        </Text>
                      </Row>
                    </Column>
                  </Column>
                  <Column className="listfluxdetransac">
                    <Column className="justify-start mt-[2px] w-[94%]">
                      <Text
                        className="font-medium text-black_900 w-[auto]"
                        variant="body2"
                      >
                        Flux de transactions
                      </Text>
                      <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                        <Img
                          src="images/img_vector2.png"
                          className="VectorThree"
                          alt="VectorThree"
                        />
                        <Img
                          src="images/img_vector1.svg"
                          className="VectorOne"
                          alt="VectorFour"
                        />
                      </Stack>
                      <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                        <Text
                          className="mt-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          lun
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          mar
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          mer
                        </Text>
                        <Text
                          className="mt-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          jeu
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          ven
                        </Text>
                        <Text
                          className="my-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          sam
                        </Text>
                        <Text
                          className="mb-[1px] text-bluegray_100 w-[auto]"
                          as="h1"
                          variant="h1"
                        >
                          dim
                        </Text>
                      </Row>
                    </Column>
                  </Column>
                </List>
                <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                  <Text className="columnfluxdetransac_two" variant="body2">
                    Flux de transactions
                  </Text>
                  <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                    <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                      <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                      <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                      <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                      <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                        <Row className="items-start justify-between w-[100%]">
                          <Text className="Thirty" variant="body2">
                            30%
                          </Text>
                          <Text className="Forty" variant="body2">
                            40%
                          </Text>
                        </Row>
                        <Text className="columnthirty" variant="body2">
                          30%
                        </Text>
                      </Column>
                    </Stack>
                  </Column>
                </Column>
                <Button
                  className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                  leftIcon={
                    <Img
                      src="images/img_camera_24X24.svg"
                      className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                      alt="camera"
                      onClick={handleNavigate3}
                    />
                  }
                  shape="RoundedBorder8"
                  size="md"
                  variant="FillIndigoA700"
                >
                  <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]"
                  onClick={handleNavigate3}>
                    Réapprovisionner
                  </div>
                </Button>
              </Column>
            </Row>
          </Column>
        </>
      );
    }
 
};

export default ListeCaissiersMasterPage;
